import { Box } from '@mui/material';
import loaderImg from 'assets/icons/loader.svg';
import classes from './SausageLoader.module.scss';


const SausageLoader = ({ sxcontainer }) => {
  return (
    <Box sx={sxcontainer} className={classes.container}>
      <Box className={classes.containerAnimation}>
        <Box className={classes.img} component='img' src={loaderImg}/>

      </Box>
    </Box>
  );
};

export default SausageLoader;

import i18n from 'i18n.js';
import i18next from 'i18next';
import { REQUEST_STATUSES, USER_NAME } from './constants';

/**
 * Creates 3 three addCases for query handling
 * @param {Object} builder
 * @param {Function} thunk
 * @param {Object} fields { status: string, data: string, error: string }
 * @param {Object} options { concat: true }
 */

export const addQueryCases = (
  builder,
  thunk,
  { status, data, error, options = { concat: true } },
) => {
  builder
    .addCase(thunk.pending, state => {
      state[ status ] = REQUEST_STATUSES.REQUESTED;
    })
    .addCase(thunk.fulfilled, (state, { payload }) => {
      state[ status ] = REQUEST_STATUSES.SUCCEEDED;
      if (options.concat) {
        state[ data ] = [ ...state[ data ], ...payload.results ];
      } else {
        state[ data ] = payload;
      }
    })
    .addCase(thunk.rejected, (state, action) => {
      state[ status ] = REQUEST_STATUSES.FAILED;
      state[ error ] = action.error;
    });
};


export const multiStorage = {
  setItem(key, value) {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  sessionSetItem(key, value) {
    if (typeof value === 'object') {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  getItem(key) {
    try {
      const localData = JSON.parse(localStorage.getItem(key));
      if (localData) return localData;
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      const localData = localStorage.getItem(key);
      if (localData) return localData;
      return sessionStorage.getItem(key);
    }
  },
  removeItem(key) {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  },
  getBasketId() {
    return this.getItem(USER_NAME)
      ?.data?.basket_id;
  },
  getRefreshToken() {
    return this.getItem(USER_NAME)?.data?.refresh_token
      ?
      this.getItem(USER_NAME)?.data?.refresh_token
      :
      this.getItem(USER_NAME)?.data?.refresh;
  },
  getAccessToken() {
    return this.getItem(USER_NAME)?.data?.access_token
      ?
      this.getItem(USER_NAME)?.data?.access_token
      :
      this.getItem(USER_NAME)?.data?.access;
  },
};

export const changeLang = (lang) => {
  i18n.changeLanguage(lang);
  const currLang = multiStorage.getItem('lang');
  if (currLang !== lang) {
    multiStorage.setItem('lang', lang);
    window.location.reload();
  }
};

export const currentLang = () => {
  const currLang = multiStorage.getItem('lang');
  i18n.changeLanguage(currLang);
};

export const cookie = {
  setItem(key, value, max_age = 2678400) {
    try {
      document.cookie = `${key}=${JSON.stringify(value)};max-age=${max_age};secure';path=/`;
    }
    catch (e) {
      document.cookie = `${key}=${value};max-age=${max_age};secure;path=/`;
    }
  },
  getItem(key) {
    const values = {};
    const cookies = document.cookie.split(/; /);
    for (let i = 0, len = cookies.length; i < len; i++) {
      const cookie = cookies[ i ].split(/=/);
      values[ cookie[ 0 ] ] = cookie[ 1 ];
    }
    try {
      return JSON.parse(values[ key ]);
    } catch (e) {
      return values[ key ];
    }
  },
  getAccessToken() {
    return this.getItem(USER_NAME)?.data?.access_token
      ?
      this.getItem(USER_NAME)?.data?.access_token
      :
      this.getItem(USER_NAME)?.data?.access;
  },
  getRefreshToken() {
    return this.getItem(USER_NAME)?.data?.refresh_token
      ?
      this.getItem(USER_NAME)?.data?.refresh_token
      :
      this.getItem(USER_NAME)?.data?.refresh;
  },
  getBasketId() {
    return this.getItem(USER_NAME)
      ?.data?.basket_id;
  },
  removeItem(key) {
    document.cookie = `${key}=deleted;max-age=-1;secure`;
  },
};

export const logOut = () => {
  multiStorage.removeItem(USER_NAME);
  document.location.reload();
};


export const similarKeys = (arg) => {
  const regExp = new RegExp(`^${arg}\\d`);
  const currLang = multiStorage.getItem('lang');
  const currentLangJSON = i18next.getResourceBundle(currLang);
  const result = currentLangJSON && Object.keys(currentLangJSON).filter(item => regExp.test(item));
  return result;
};


export const maskPhoneNumber = phoneNumber => {
  // Remove all non-digit characters from the phone number
  let digitsOnly = phoneNumber.replace(/[^\d]/g, '');
  // If the phone number doesn't start with '996', add it
  if (!digitsOnly.startsWith('996')) {
    digitsOnly = '996' + phoneNumber;
  }
  if (!digitsOnly.startsWith('+996')) {
    digitsOnly = digitsOnly.replace(/(996)/, '+$1 ');
  }
  // Format the phone number as +996 (xxx) xx-xx-xx
  // eslint-disable-next-line max-len
  const formatted = digitsOnly.replace(/(^\+996 |^996)?(\d{3})(\d{2})(\d{2})(\d{2})/, (match, prefix, first, second, third, four) => {
    // If the match starts with '+996 ' or '996 ', remove it
    if (prefix) {
      return `+996 (${first}) ${second}-${third}-${four}`;
    } else {
      return `${first} (${second}) ${third}-`;
    }
  });
  // Return the formatted phone number
  return formatted.slice(0, 19);
};


export const formatNumber = (value) => {
  const cleanedNumber = value.split('+996')?.[ 1 ].replace(/\D/g, '');
  if (value.length > 0) {
    // eslint-disable-next-line max-len
    return `+996 (${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 5)}-${cleanedNumber.slice(5, 7)}-${cleanedNumber.slice(7, 9)}`;
  }
};

export const PATHS = {
  main: '/',
  products: '/products',
  about: '/about',
  blogs: '/blogs',
  blog: '/blogs/:id',
  vacancies: '/vacancies',
  respondVacancy: '/vacancies/:id',
  profile: '/profile',
  auth: '/auth',
  product: '/products/:id',
  search: '/search/',
  cookie: '/cookie/',
  cart: '/cart/',
};


export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const USER_NAME = 'user';
export const COOKIE_ACCEPT = 'cookie_accept';


export const REQUEST_STATUSES = {
  NOT_REQUESTED: 'notRequested',
  REQUESTED: 'requested',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const CART_NAME = 'cart';

export const regExpLogin = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$|^\+996 \(\d{3}\) \d{2}-\d{2}-\d{2}$/;

export const regExpPhone = /^\+996 \(\d{3}\) \d{2}-\d{2}-\d{2}$/;

export const regExpFullName = /[A-zА-я]{2,50}/;

export const siteMap = [
  {
    id: 1,
    title: 'main',
    url: 'http://salih.kg/',
  },
  {
    id: 2,
    title: 'about',
    url: 'https://salih.kg/about',
  },
  {
    id: 4,
    title: 'production',
    url: 'https://salih.kg/about?tab=production',
  },
  {
    id: 5,
    title: 'products',
    url: 'https://salih.kg/products',
  },
  {
    id: 6,
    title: 'blogs',
    url: 'https://salih.kg/blogs',
  },
  {
    id: 7,
    title: 'news',
    url: 'https://salih.kg/blogs',
  },
  {
    id: 8,
    title: 'recipes',
    url: 'https://salih.kg/blogs?blog_type=recipes',
  },
];

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { lazily } from 'react-lazily';
import { changeLang, multiStorage } from 'common/helpers';
import SausageLoader from 'components/UI/SausageLoader/SausageLoader';
import './index.css';
import './assets/fonts/stylesheet.css';
import reportWebVitals from './reportWebVitals';
import './i18n';


if (!multiStorage.getItem('lang')) {
  changeLang('ru');
}

const { BrowserRouter } = lazily(() => import('react-router-dom')) ;


const App = React.lazy(() => import('./App')) ;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<SausageLoader sxcontainer={{ height: '100vh' }}/>}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
  ,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
